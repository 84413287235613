import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import Container from "../../components/container"
import { HeroButtonWhite } from "../../components/buttons"

export default function PageNotFound({ data }) {
    return (
        <Layout title="404" lang="en" actSite="">
            <Container
                image={data.naImg.childImageSharp.fluid}
                centered={
                    <div>
                        404<br/>
                        This Site was not found!<br/>
                        <HeroButtonWhite
                            to="/en/"
                            text="Back to Home"
                        />
                    </div>
                }
            />
        </Layout>
    )
}

export const query = graphql`
    query {
        naImg: file(relativePath: { eq: "404Door.webp" }) {
            childImageSharp {
                fluid(maxWidth: 2300, webpQuality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
